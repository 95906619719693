import React from 'react'
import Immutable from 'immutable'
import $ from 'jquery'
import { Store } from 'pullstate'
import DatePicker, { registerLocale } from 'react-datepicker'
import de from 'date-fns/locale/de'
import SweetAlert from 'react-bootstrap-sweetalert'

registerLocale('de', de)

const CheckoutStore = new Store({
  process: null,
  validations: [],
  cart_html: '',
  submitting: false,
  openingTimes: []
})

const DateRepo = {
  weekDay (date) {
    const wday = date.getDay()
    if (wday === 0) {
      return 7
    } else {
      return wday
    }
  },
  oneWeekFromToday () {
    const today = new Date()
    return Array.from(Array(7).keys()).map((day) => {
      const date = new Date()
      date.setDate(today.getDate() + day)
      return date
    })
  },
  hoursAndMinutes () {
    const date = new Date()
    return {
      minutes: date.getMinutes(),
      hours: date.getHours()
    }
  }
}

const EmailForm = (props) => {
  const handleInputChange = React.useCallback(
    (event) => {
      const newProcess = props.process.set(
        event.target.name,
        event.target.value
      )

      props.onProcessChange(newProcess)
    },
    [props.process, props.onProcessChange]
  )

  let emailClass = 'form-control string email required'
  let emailErrorText = ''
  if (props.validations.email) {
    emailClass += ' is-invalid'
    emailErrorText = props.validations.email.join(', ')
  }

  let phoneClass = 'form-control string phone required'
  let phoneErrorText = ''
  if (props.validations.phone) {
    phoneClass += ' is-invalid'
    phoneErrorText = props.validations.phone.join(', ')
  }

  let deliveryNameClass = 'form-control b4'
  if (props.validations.delivery_name) {
    deliveryNameClass += ' is-invalid'
  }

  let deliveryLastNameClass = 'form-control b4'
  if (props.validations.delivery_lastname) {
    deliveryLastNameClass += ' is-invalid'
  }

  return (
    <div className='row'>
      <div className='col-md-6'>
        <div className='form-group b4 required checkout_process_delivery_name'>
          <label
            className='form-control-label b4 required'
            htmlFor='checkout_process_delivery_name'
          >
            Name
          </label>

          <input
            className={deliveryNameClass}
            type='text'
            value={props.process.get('delivery_name')}
            name='delivery_name'
            onChange={handleInputChange}
          />

          <div className='invalid-feedback'>
            {props.validations.delivery_name}
          </div>
        </div>
      </div>

      <div className='col-md-6 form-group b4 required checkout_process_delivery_lastname'>
        <label
          className='form-control-label b4 required'
          htmlFor='checkout_process_delivery_lastname'
        >
          Nachname
        </label>

        <input
          className={deliveryLastNameClass}
          type='text'
          name='delivery_lastname'
          onChange={handleInputChange}
          value={props.process.get('delivery_lastname')}
          id='checkout_process_delivery_lastname'
        />

        <div className='invalid-feedback'>
          {props.validations.delivery_lastname}
        </div>
      </div>

      <div className='col-md-6'>
        <div className='form-group email required checkout_process_email'>
          <label
            className='form-control-label email required'
            htmlFor='checkout_process_email'
          >
            E-Mail
          </label>
          <input
            className={emailClass}
            type='email'
            name='email'
            onChange={handleInputChange}
            value={props.process.get('email')}
          />

          <div className='invalid-feedback'>{emailErrorText}</div>
        </div>
      </div>

      <div className='col-md-6'>
        <div className='form-group email required checkout_process_email'>
          <label
            className='form-control-label email required'
            htmlFor='checkout_process_email'
          >
            Telefonnummer
          </label>
          <input
            className={phoneClass}
            type='text'
            name='phone'
            onChange={handleInputChange}
            value={props.process.get('phone')}
          />

          <div className='invalid-feedback'>{phoneErrorText}</div>
        </div>
      </div>
    </div>
  )
}

const AddressFrom = (props) => {
  const handleInputChange = React.useCallback(
    (event) => {
      // if (
      //   event.target.name === 'delivery_city'
      //   //   &&
      //   // event.target.value === 'Ich hole selbst ab'
      // ) {
      //   const newProcess = props.process.set('shipping_method', 'fetch');
      //
      //   props.onProcessChange(newProcess);
      // } else {
      const newProcess = props.process.set(
        event.target.name,
        event.target.value
      )

      props.onProcessChange(newProcess)
      // }
    },
    [props.onProcessChange, props.process]
  )

  let deliveryStreetClass = 'form-control b4'
  if (props.validations.delivery_street) {
    deliveryStreetClass += ' is-invalid'
  }

  let deliveryPostcodeClass = 'form-control b4'
  if (props.validations.delivery_postcode) {
    deliveryPostcodeClass += ' is-invalid'
  }

  let deliveryCityClass = 'form-control b4'
  if (props.validations.delivery_city) {
    deliveryCityClass += ' is-invalid'
  }

  let cityInput = (
    <input
      className={deliveryCityClass}
      type='text'
      name='delivery_city'
      onChange={handleInputChange}
      value={props.process.get('delivery_city')}
      id='checkout_process_delivery_city'
    />
  )
  if (props.process.get('shipping_method') === 'deliver') {
    cityInput = (
      <select
        name='delivery_city'
        className={deliveryCityClass}
        onChange={handleInputChange}
      >
        <option value='' />
        {/* <option>Ich hole selbst ab</option> */}
        {props.cities.map((city) => (
          <option key={city}>{city}</option>
        ))}
      </select>
    )
  }

  return (
    <div className='row'>
      <div className='col-md-12'>
        <h3 className='box-title'>Lieferadresse</h3>

        <div className='form-group b4 required checkout_process_delivery_street'>
          <label
            className='form-control-label b4 required'
            htmlFor='checkout_process_delivery_street'
          >
            Straße
          </label>
          <input
            className={deliveryStreetClass}
            type='text'
            name='delivery_street'
            value={props.process.get('delivery_street')}
            onChange={handleInputChange}
            id='checkout_process_delivery_street'
          />

          <div className='invalid-feedback'>
            {props.validations.delivery_street}
          </div>
        </div>

        <div className='form-group b4 required checkout_process_delivery_postcode'>
          <label
            className='form-control-label b4 required'
            htmlFor='checkout_process_delivery_postcode'
          >
            Postleitzahl
          </label>
          <input
            className={deliveryPostcodeClass}
            type='text'
            onChange={handleInputChange}
            value={props.process.get('delivery_postcode')}
            name='delivery_postcode'
            id='checkout_process_delivery_postcode'
          />

          <div className='invalid-feedback'>
            {props.validations.delivery_postcode}
          </div>
        </div>

        <div className='form-group b4 required checkout_process_delivery_city'>
          <label
            className='form-control-label b4 required'
            htmlFor='checkout_process_delivery_city'
          >
            Ort
          </label>

          {cityInput}

          <div className='invalid-feedback'>
            {props.validations.delivery_city}
          </div>
        </div>
      </div>

      <div className='col-md-12'>
        <a
          className='collapseButton'
          data-toggle='collapse'
          href='#collapseExample'
          role='button'
          aria-expanded='false'
          aria-controls='collapseExample'
        >
          Rechnungsadresse
        </a>

        <div className='collapse' id='collapseExample'>
          <div>
            <div className='form-group string optional checkout_process_billing_name'>
              <label
                className='form-control-label string optional'
                htmlFor='checkout_process_billing_name'
              >
                Name
              </label>
              <input
                className='form-control string optional'
                placeholder='Wie Lieferadresse'
                type='text'
                name='checkout_process[billing_name]'
                id='checkout_process_billing_name'
              />
            </div>

            <div className='form-group string optional checkout_process_billing_lastname'>
              <label
                className='form-control-label string optional'
                htmlFor='checkout_process_billing_lastname'
              >
                Nachname
              </label>
              <input
                className='form-control string optional'
                placeholder='Wie Lieferadresse'
                type='text'
                name='checkout_process[billing_lastname]'
                id='checkout_process_billing_lastname'
              />
            </div>

            <div className='form-group string optional checkout_process_billing_street'>
              <label
                className='form-control-label string optional'
                htmlFor='checkout_process_billing_street'
              >
                Straße
              </label>
              <input
                className='form-control string optional'
                placeholder='Wie Lieferadresse'
                type='text'
                name='checkout_process[billing_street]'
                id='checkout_process_billing_street'
              />
            </div>

            <div className='form-group string optional checkout_process_billing_postcode'>
              <label
                className='form-control-label string optional'
                htmlFor='checkout_process_billing_postcode'
              >
                Postleitzahl
              </label>
              <input
                className='form-control string optional'
                placeholder='Wie Lieferadresse'
                type='text'
                name='checkout_process[billing_postcode]'
                id='checkout_process_billing_postcode'
              />
            </div>

            <div className='form-group string optional checkout_process_billing_city'>
              <label
                className='form-control-label string optional'
                htmlFor='checkout_process_billing_city'
              >
                Stadt
              </label>
              <input
                className='form-control string optional'
                placeholder='Wie Lieferadresse'
                type='text'
                name='checkout_process[billing_city]'
                id='checkout_process_billing_city'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const CartForm = (props) => (
  <div dangerouslySetInnerHTML={{ __html: props.cartHtml }} />
)

const PAYMENT_METHOD_MAPPING = {
  1: 'bar',
  2: 'paypal',
  3: 'ec',
  5: 'bill'
}

const PAYMENT_NAME_MAPPING = {
  1: 'Bar',
  2: 'PayPal',
  3: 'EC',
  5: 'Rechnung'
}

const PaymentSelector = (props) => {
  const handleInputChange = React.useCallback(
    (method) => {
      const newProcess = props.process.set('payment_method', method)

      props.onProcessChange(newProcess)
    },
    [props.onProcessChange, props.process]
  )

  const ICON_MAPPING = {
    1: <ion-icon class='ion-cash' style={{ alignSelf: 'center' }} />,
    2: (
      <img
        style={{ objectFit: 'contain' }}
        className='img-fluid'
        src='/images/paypal-100px.png'
      />
    )
  }

  const paymentMethods = props.supportedPaymentMethods

  const radioButtons = paymentMethods.map((method) => {
    const checked =
      props.process.get('payment_method') === PAYMENT_METHOD_MAPPING[method]
    let labelCls = 'btn payment-button'
    if (checked) {
      labelCls += ' active'
    }
    return (
      <label
        onClick={() => handleInputChange(PAYMENT_METHOD_MAPPING[method])}
        className={labelCls}
        key={method}
      >
        <input
          type='radio'
          name='payment_method'
          value={PAYMENT_METHOD_MAPPING[method]}
        />
        <div
          style={{
            flex: 0.7,
            alignContent: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {ICON_MAPPING[method]}
        </div>

        <div style={{ flex: 0.3 }}>{PAYMENT_NAME_MAPPING[method]}</div>
      </label>
    )
  })

  return (
    <div className='my-4'>
      <h4>Zahlungsmethode</h4>
      <div style={{ display: 'flex', padding: 3, overflowX: 'auto' }}>
        <div className='btn-group btn-group-toggle' data-toggle='buttons'>
          {radioButtons}
        </div>
      </div>
    </div>
  )
}

const CommentForm = (props) => {
  const handleInputChange = React.useCallback(
    (event) => {
      const newProcess = props.process.set('comment', event.target.value)

      props.onProcessChange(newProcess)
    },
    [props.process, props.onProcessChange]
  )

  const handleTimeChange = React.useCallback(
    (event) => {
      const newProcess = props.process.set('delivery_time', event.target.value)

      props.onProcessChange(newProcess)
    },
    [props.process, props.onProcessChange]
  )

  let openingTimes = null
  const wday = DateRepo.weekDay(new Date(props.process.get('day')))
  const times = [
    ...new Set(
      props.openingTimes
        .filter((openingTime) => openingTime.day === wday)
        .map((t) => t.times)
        .flat()
    )
  ].sort()

  openingTimes = times.map((time) => (
    <option key={time} value={time}>
      {time}
    </option>
  ))

  const openDays = [
    ...new Set(props.openingTimes.map((openingTime) => openingTime.day))
  ]

  const dates = DateRepo.oneWeekFromToday().filter((day) =>
    openDays.includes(DateRepo.weekDay(day))
  )

  const currentWDay = DateRepo.weekDay(new Date())
  const closedCurrentDay = !props.openingTimes.some(
    (openingTime) => openingTime.day === currentWDay
  )

  return (
    <>
      {props.preorder && closedCurrentDay && (
        <div className='alert alert-warning' role='alert'>
          Heute haben wir geschlossen, aber sie können gerne für einen anderen
          Tag vorbestellen.
        </div>
      )}
      {props.preorder && (
        <div className='form-group'>
          <label>Tag</label>
          <div>
            <DatePicker
              className='form-control'
              selected={new Date(props.process.get('day'))}
              includeDates={dates}
              dateFormat='dd.MM.'
              locale='de'
              onChange={(date) => {
                props.onProcessChange(props.process.set('day', date))
              }}
            />
            {props.validations.delivery_time && (
              <div className='alert alert-danger'>
                Bitte bestellen Sie zu unseren Öffnungszeiten.
              </div>
            )}
          </div>
        </div>
      )}

      <div className='form-group'>
        <label>Uhrzeit</label>
        <select className='form-control' onChange={handleTimeChange}>
          {!props.process.get('preorder') && (
            <option value={null}>Schnellstmöglich </option>
          )}
          {openingTimes}
        </select>
      </div>

      <div className='form-group'>
        <label>Hinweis zur Bestellung</label>
        <textarea
          className='form-control'
          onChange={handleInputChange}
          value={props.process.get('comment')}
          rows='3'
        />
      </div>
    </>
  )
}

const Agreements = (props) => {
  const handleInputChange = React.useCallback(
    (event) => {
      const newProcess = props.process.set(
        event.target.name,
        event.target.checked
      )

      props.onProcessChange(newProcess)
    },
    [props.process, props.onProcessChange]
  )

  let tosClass = 'form-check-input'
  if (props.validations.accepted_tos) {
    tosClass += ' is-invalid'
  }

  let revokeClass = 'form-check-input'
  if (props.validations.accepted_revoke) {
    revokeClass += ' is-invalid'
  }

  return (
    <div className='my-4'>
      <div className='form-group'>
        <div className='form-check'>
          <input
            type='checkbox'
            checked={props.process.accepted_tos}
            name='accepted_tos'
            required
            onChange={handleInputChange}
            className={tosClass}
          />

          <label className='form-check-label'>
            Zustimmung zu{' '}
            <a href='#' data-toggle='modal' data-target='#agb-modal'>
              AGB
            </a>
          </label>

          <div className='invalid-feedback'>
            {props.validations.delivery_postcode}
          </div>
        </div>
      </div>
      <div className='form-group'>
        <div className='form-check'>
          <input
            type='checkbox'
            checked={props.process.accepted_revoke}
            onChange={handleInputChange}
            name='accepted_revoke'
            className={revokeClass}
          />

          <label className='form-check-label'>
            Zustimmung zu{' '}
            <a href='#' data-toggle='modal' data-target='#revoke-modal'>
              Widerrufsbelehrung
            </a>
          </label>
        </div>
      </div>
    </div>
  )
}

export default function Checkout (props) {
  const timeout = React.useRef()
  const {
    process,
    validations,
    cart_html,
    submitting
  } = CheckoutStore.useState()

  const [url, setUrl] = React.useState('')
  const [showPayPalDialog, setShowPayPalDialog] = React.useState(false)

  React.useEffect(() => {
    for (const key in props.process) {
      if (props.process[key] === null) {
        props.process[key] = ''
      }
    }

    CheckoutStore.update((s) => {
      s.process = Immutable.fromJS(props.process)
      s.validations = props.validations
      s.cart_html = props.cart_html
    })
  }, [])

  const onSubmit = () => {
    if (Object.keys(validations).length !== 0) {
      return
    }

    CheckoutStore.update((s) => {
      s.submitting = true
    })

    $.ajax({
      url: props.release_url,
      data: {
        checkout_process: process.toJS()
      },
      method: 'POST'
    })
      .always(() => {
        CheckoutStore.update((s) => {
          s.submitting = false
        })
      })
      .done((response) => {
        if (response.errorcode === 1) {
          CheckoutStore.update((s) => {
            s.validations = response.errors
          })
        } else if (response.errorcode === 2) {
          alert('Restaurant leider geschlossen')
          window.location.replace('/')
        } else {
          setShowPayPalDialog(true)
          setTimeout(() => {
            window.location.href = response.url
          }, 1000)
        }
      })
  }

  const onProcessChange = (process) => {
    CheckoutStore.update(
      (s) => {
        s.process = process
      },
      (previous, current) => {
        clearTimeout(timeout.current)

        timeout.current = setTimeout(() => {
          $.ajax({
            url: props.update_url,
            data: {
              checkout_process: process.toJS()
            },
            method: 'POST'
          }).done((response) => {
            CheckoutStore.update((s) => {
              s.validations = response.validations
              s.cart_html = response.cart_html
            })
          })
        }, 300)
      }
    )
  }

  let submitBtnClass = 'btn btn-success'

  if (Object.keys(validations).length !== 0 || submitting) {
    submitBtnClass += ' disabled'
  }

  if (process !== null) {
    return (
      <div>
        {showPayPalDialog && (
          <SweetAlert
            success
            title='Bestellung abgeschlossen.'
            customButtons={<></>}
            onConfirm={() => {}}
          >
            <span>
              Sie werden zu PayPal weiter geleitet. Falls sie nicht weiter
              geleitet werden, klicken sie <a href={url}>hier</a>
            </span>
          </SweetAlert>
        )}
        <EmailForm
          validations={validations}
          process={process}
          onProcessChange={onProcessChange}
        />
        <AddressFrom
          validations={validations}
          process={process}
          onProcessChange={onProcessChange}
          cities={props.cities}
        />
        <CartForm cartHtml={cart_html} />
        <CommentForm
          openingTimes={props.opening_times}
          process={process}
          onProcessChange={onProcessChange}
          preorder={props.preorder}
          validations={validations}
        />
        <PaymentSelector
          supportedPaymentMethods={props.supported_payment_methods}
          validations={validations}
          process={process}
          onProcessChange={onProcessChange}
        />
        <Agreements
          validations={validations}
          process={process}
          onProcessChange={onProcessChange}
        />

        <div style={{ marginBottom: 10 }}>
          <button onClick={onSubmit} className={submitBtnClass}>
            Jetzt kostenpflichtig bestellen
          </button>
        </div>
      </div>
    )
  } else {
    return <div />
  }
}
